import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { PageWrapper } from '../components/PageWrapper';
import { Navigator } from '../components/Navigator';
import { HeroSimple } from '../components/HeroSimple';
import MainButton from '../components/Button';
import FooterCta from '../components/FooterCta';

import { ArrowUpRightIcon } from '@heroicons/react/20/solid'

const ServiceRootTemplate = ({
    data: {
        datoCmsServiceRoot: {
            seo,
            title,
            subtitle,
            heroImage,
            hero: [{
                heroTitle,
                heroSpan1,
                heroSubtitle,
            }],
        },
        datoCmsContactpage: { 'id': contactPageId},
        allDatoCmsServiceRecord: { serviceRecordNodes },
    },
    pageContext,
}) => (
    <PageWrapper
        pageData={pageContext}
        seoTitle={seo?.seoTitle}
        seoDescription={seo?.seoDescription}
        seoImage={seo?.seoImage?.seoImageUrl}
        seo
    >
        <div className='ServiceRootNavBackground'></div>
        <div className='ServiceRootHeroWrapper'>
            <div className='ServiceRootHeroContainer'>
                <div className='ServiceRootHeroInner'>
                    <p className='ServiceRootHeroAlt'>Services</p>
                    <h1 className='ServiceRootHeroTitle'>{heroTitle}<br/>{heroSpan1}</h1>
                </div>
                <div className='ServiceRootHeroInner'>
                    <p className='ServiceRootHeroText'>{heroSubtitle}</p>
                </div>
            </div>
        </div>

        <div className='ServiceRootWrapper'>
            <div className='ServiceRootCardWrapper'>
                {serviceRecordNodes.map(({ id, title, subtitle, heroImage }) => (
                    <Navigator className='ServiceRootCardContainer' recordId={id} key={id}>
                        <div className='ServiceRootCardTextWrapper'>
                            <div className='ServiceRootCardTextContainer'>
                                <a className='ServiceRootCardTitle'>{title}</a>
                                <p className='ServiceRootCardSubtitle'>{subtitle}</p>
                                <Navigator className='ServiceRootCardButton' recordId={id}>
                                    Explore <ArrowUpRightIcon className='ServiceRootCardButtonIcon' />
                                </Navigator>
                            </div>
                        </div>
                        <GatsbyImage image={heroImage.gatsbyImageData} alt={heroImage.alt} className='ServiceRootCardImage' />
                    </Navigator>    
                ))}
            </div>
        </div>

       
        <FooterCta />
        
    </PageWrapper>
);

export default ServiceRootTemplate;

export const query = graphql`
    query ServiceRootQuery($locale: String!) {
        allDatoCmsServiceRecord(
            filter: {
                locale: { eq: $locale }
            }
            sort: { fields: level, order: ASC }
        ) {
        serviceRecordNodes: nodes {
            locale
            id: originalId
            level
            title
            subtitle
            heroImage {
                gatsbyImageData(
                    imgixParams: { 
                        h: "500"
                    }
                )
            }
        }}
        datoCmsContactpage(locale: { eq: $locale }) {
            id: originalId
        }
        datoCmsServiceRoot(locale: { eq: $locale }) {
            locale
            seo {
                seoTitle: title
                seoDescription: description
                seoImage: image {
                    seoImageUrl: url
                }
            }
            hero {
                heroTitle
                heroSpan1
                heroSubtitle
            }
            title
            subtitle
            heroImage {
                gatsbyImageData(
                    imgixParams: { invert: false }
                )
            }
        }
    
}
`;